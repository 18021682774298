import React, {useEffect, useRef, useState} from 'react';
import numeral from 'numeral';
import CoinService from "../../service/coin.service";
import {Chart, registerables} from "chart.js";
import {Line, Bar} from "react-chartjs-2";
import {AppType, Period} from "../../helper/models";
import {getCurrencyValueByKey} from "../../constants/constants";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);


const ChartPage = ({setVisible, appType, coinData, period, currency}) => {
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [error, setError] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [crossDate, setCrossDate] = useState(null);
    // const [crossPrice, setCrossPrice] = useState(null);

    const [chartPeriod, setChartPeriod] = useState(period);
    const chartRef = useRef(null);

    const [chartMax, setChartMax] = useState({
        val: null,
        used: false
    });
    const [chartMin, setChartMin] = useState({
        val: null,
        used: false
    });
    const { t } = useTranslation();


    useEffect(() => {
        getChartData();
    }, [chartPeriod]);

    function formatDate(element) {

        const date = new Date(element * 1000);
        //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();

        return `${day}.${month}.${year} ${hours}:${minutes.substr(-2)}`;
    }

    function roundToThreeSignificantDigits(num) {
        const numStr = num.toString();

        // Разделяем число на целую и дробную части
        const [integerPart, fractionalPart] = numStr.split('.');

        // Если нет дробной части, возвращаем число как есть
        if (!fractionalPart) {
            return numStr;
        }

        // Регулярное выражение для выделения трех значащих цифр после точки
        const match = fractionalPart.match(/^(0*\d{1,3})(0*)/);

        if (match) {
            const significantDigits = match[1];  // Первые значащие цифры
            const trailingZeros = match[2];      // Последующие нули

            // Формируем окончательное число
            return `${integerPart}.${significantDigits}${trailingZeros}`;
        }

        // Если дробная часть не соответствует формату, возвращаем число как есть
        return numStr;
    }

    async function getChartData() {
        setIsChartLoading(true);
        setError(null);
        const coinId = coinData.id;

        try {
            const response = await CoinService.getCoinChart(appType, coinId, chartPeriod, currency);
            const formattedResponse = response.map(e => {
                // e.p = roundToThreeSignificantDigits(e.p);
                return e;
            });

            let tempMax = null;
            let tempMin = null;
            formattedResponse.forEach(e => {
                if (tempMax == null || tempMax < e.p) {
                    tempMax = e.p
                }
                if (tempMin == null || tempMin > e.p) {
                    tempMin = e.p
                }
            });

            setChartMax(tempMax)
            setChartMin(tempMin)

            const data = {
                // labels: response.data.map(e => {
                labels: formattedResponse.map(e => {
                    return formatDate(e.t);
                }),
                datasets: [{
                    // data: response.data.map(e => e.p),
                    data: formattedResponse.map(e => {
                        return e.p;
                    }),
                    datalabels: {
                        align: 'start',
                        anchor: 'start'
                    },
                    backgroundColor: (context) => {

                        if (!context.chart.chartArea) {
                            return;
                        }

                        const {ctx, data, chartArea: {top, bottom}} = context.chart;

                        var gradient = ctx.createLinearGradient(0, top, 0, bottom);
                        gradient.addColorStop(0, 'rgba(0, 119, 221, 1)');
                        gradient.addColorStop(1, 'rgba(0, 119, 221, 0)');

                        return gradient;

                    },
                    borderColor: 'white',
                    borderWidth: 1,
                    fill: true,
                    pointRadius: 0
                }],
            }

            setChartData(data);

        } catch (e) {
            console.log(e.message)
            setError(e.message);
            setChartData(null);
            setCrossDate(null);
            setChartMax({
                val: null,
                used: false
            });
            setChartMin({
                val: null,
                used: false
            });
        }

        setIsChartLoading(false);
    }

    // const scatterDataLabels = {
    //     id: 'scatterDataLabels',
    //     afterDatasetsDraw(chart, args, options, cancelable, k1, k2, k3) {
    //         const {ctx} = chart;
    //         ctx.save()
    //
    //         for (let x = 0; x< chart.config.data.datasets.length; x++) {
    //             console.log('chart.config.data.datasets[x] = ', chart.config.data.datasets[x])
    //         }
    //     }
    // }

    function getChartTooltipPositionX(chartWidth, x, chartValueWidth) {
        if (x+chartValueWidth/2 > chartWidth) {
            return chartWidth-chartValueWidth/2;
        } else if (x-chartValueWidth/2 < 0) {
            return chartValueWidth/2;
        }
        return x;
    }

    function getChartLabelsPositionX(chartWidth, x, chartValueWidth) {
        if (x < 5) {
            return 5;
        } else if (x+chartValueWidth > chartWidth) {
            return chartWidth-chartValueWidth-5;
        }
        return x;
    }

    let crossHair;
    const crosshairLabel = {
        id: "crosshairLabel",
        afterDatasetsDraw(chart, args, options, cancelable, k1, k2, k3) {
            const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;

            let chartMaxIsDisplayed = false
            let chartMinIsDisplayed = false

            ctx.font = 'bold 16px Verdana,Arial,sans-serif'

            for (let x = 0; x < chart.config.data.datasets.length; x++) {
                for (let i = 0; i < chart.config.data.datasets[x].data.length; i++) {
                    const chartValue = chart.config.data.datasets[x].data[i]
                    const chartValueForDisplay = roundToThreeSignificantDigits(chart.config.data.datasets[x].data[i])

                    if (chartValue == chartMax && !chartMaxIsDisplayed) {
                        const chartValueForDisplayMeasure = ctx.measureText(chartValueForDisplay);
                        const chartLabelsPositionX = getChartLabelsPositionX(chart.width, chart.getDatasetMeta(x).data[i].x, chartValueForDisplayMeasure.width)
                        // ctx.fillStyle = 'white'
                        ctx.fillStyle = 'rgba(0, 203, 0, 1)'
                        ctx.fillText(chartValueForDisplay, chartLabelsPositionX, chart.getDatasetMeta(x).data[i].y+14)
                        chartMaxIsDisplayed = true

                        ctx.fillRect(chart.getDatasetMeta(x).data[i].x-3, chart.getDatasetMeta(x).data[i].y-3, 6, 6)
                    }
                    if (chartValue == chartMin && !chartMinIsDisplayed) {
                        const chartValueForDisplayMeasure = ctx.measureText(chartValueForDisplay);
                        const chartLabelsPositionX = getChartLabelsPositionX(chart.width, chart.getDatasetMeta(x).data[i].x, chartValueForDisplayMeasure.width)
                        // console.log('chartLabelsPositionX 2= ', chartLabelsPositionX)
                        // ctx.fillStyle = 'white'
                        // ctx.fillRect(chartLabelsPositionX, chart.getDatasetMeta(x).data[i].y-18, chartValueMeasure.width, 18)
                        ctx.fillStyle = '#f66'
                        ctx.fillText(chartValueForDisplay, chartLabelsPositionX, chart.getDatasetMeta(x).data[i].y)
                        chartMinIsDisplayed = true

                        ctx.fillRect(chart.getDatasetMeta(x).data[i].x-3, chart.getDatasetMeta(x).data[i].y-3, 6, 6)
                    }
                }
            }

            if (crossHair) {
                ctx.save()
                ctx.beginPath()
                ctx.lineWidth = 2
                ctx.strokeStyle = 'grey'
                ctx.fillStyle = 'grey'
                crossHair.forEach((line) => {
                    ctx.moveTo(line.startX, line.startY)
                    ctx.lineTo(line.endX, line.endY)
                })
                ctx.stroke()
                const tooltipValue = `${roundToThreeSignificantDigits(y.getValueForPixel(crossHair[0].startY))}${getCurrencyValueByKey(currency)}`
                const tooltipValueMeasureWidth = ctx.measureText(tooltipValue).width + 10;
                const tooltipValuePositionX = getChartTooltipPositionX(chart.width, crossHair[1].startX, tooltipValueMeasureWidth)

                ctx.fillRect(tooltipValuePositionX-tooltipValueMeasureWidth/2, 0 , tooltipValueMeasureWidth, 20)
                ctx.textAlign = 'center'
                ctx.fillStyle = 'white'

                ctx.fillText(tooltipValue, tooltipValuePositionX, 18)


                ctx.restore()
            }
        },

        afterEvent(chart, args, options, a, b, c, event) {
            const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;

            const xCoor = args.event.x;
            const yCoor = args.event.y;

            const elements = chart.getElementsAtEventForMode(args.event, 'nearest', { axis: "x" }, false);

            const el = elements[0]?.element.y;
            let tempCrossDate = null;
            // let tempCrossPrice = null;

                if (elements.length) {

                    // Получаем первую ближайшую точку
                    const closestElement = elements[0];
                    // const datasetIndex = closestElement.datasetIndex;
                    const index = closestElement.index;
                    // const priceValue = chartData.datasets[datasetIndex].data[index];
                    const dateValue = chartData.labels[index];
                    // tempCrossPrice = priceValue;
                    tempCrossDate = dateValue;
                }

            if (!args.inChartArea && crossHair) {
                crossHair = null;
                setCrossDate(null);
                // setCrossPrice(null);
            } else if (args.inChartArea) {
                crossHair = [
                    {
                        startX: left,
                        startY: el,
                        endX: right,
                        endY: el, // 268.6507936507935
                    },
                    {
                        startX: xCoor,
                        startY: top,
                        endX: xCoor,
                        endY: bottom,
                    }
                ];
                setCrossDate(tempCrossDate);
                // setCrossPrice(tempCrossPrice);
            }
            args.changed = true;
        }
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            line: {
                tension : 0.1  // smooth lines
            },
        },
        scales: {
            x: {
                display: false,
                    border: {
                    display: false,
                },
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                    border: {
                    display: false,
                },
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        hover: {
            mode: 'nearest',
            axis: "x",
            intersect: false
        },
        onHover: (event, elements) => {
            // console.log('elements = ', elements)
            // if (elements.length) {
            //     const datasetIndex = elements[0].datasetIndex;
            //     const index = elements[0].index;
            //     const value = data.datasets[datasetIndex].data[index];
            //     // setHoveredData(value);
            // } else {
            //     // setHoveredData(null);
            // }
        },
    }

    return (
        <div className={'chart-section'}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', padding: '10px 10px 0'}}>
                <div style={{display: 'flex',  gap: '5px'}}>
                    {/*<img style={{height: '24px'}} src={`https://cryptobubbles.net/backend/data/logos/${coinData.id}.png`} alt={coinData.symbol} />*/}
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px', marginRight: '10px'}}><span>{coinData.symbol}</span></div>

                    <a className={'chart-link-button'}
                       href={`https://coinmarketcap.com/currencies/${coinData.cmc_id}`}
                       target={'_blank'}
                    >
                        <svg style={{height: '24x', width: '24px'}} viewBox="-7 -7 55 55">
                            <rect x="-7" y="-7" width="55" height="55" fill="#3861fb" rx="12"></rect>
                            <path d="m35.124 24.5c-0.715 0.452-1.557 0.508-2.197 0.147-0.813-0.459-1.26-1.534-1.26-3.029v-4.473c0-2.16-0.854-3.697-2.282-4.112-2.42-0.705-4.24 2.256-4.924 3.368l-4.268 6.92v-8.458c-0.048-1.946-0.68-3.11-1.88-3.461-0.794-0.232-1.982-0.139-3.136 1.627l-9.562 15.354c-1.2801-2.4302-1.9475-5.1363-1.944-7.883 0-9.249 7.412-16.773 16.522-16.773s16.521 7.524 16.521 16.773c0 0.016 4e-3 0.03 5e-3 0.045 0 0.016-3e-3 0.03-2e-3 0.046 0.086 1.791-0.494 3.216-1.593 3.91zm5.261-3.999v-0.047l-1e-3 -0.046c-0.051-11.264-9.088-20.408-20.192-20.408-11.133 0-20.192 9.196-20.192 20.5 0 11.303 9.059 20.5 20.193 20.5 5.109 0 9.985-1.942 13.728-5.467 0.744-0.7 0.788-1.879 0.098-2.633-0.68394-0.7542-1.854-0.79931-2.594-0.1-3.0339 2.873-7.0536 4.4738-11.232 4.473-4.878 0-9.267-2.159-12.294-5.583l8.623-13.846v6.383c0 3.066 1.189 4.057 2.186 4.347 0.998 0.29 2.523 0.092 4.124-2.508l4.743-7.689c0.152-0.248 0.292-0.462 0.42-0.647v3.888c0 2.866 1.148 5.158 3.149 6.287 1.804 1.018 4.072 0.926 5.92-0.24 2.24-1.415 3.447-4.022 3.321-7.164z" fill="#fff">
                            </path>
                        </svg>
                    </a>
                    <a className={'chart-link-button'}
                       href={`https://www.coingecko.com/coins/${coinData.cg_id}`}
                       target={'_blank'}
                    >
                        <svg style={{height: '24px', width: '24px'}} viewBox="0 0 96 96">
                            <path d="M95.3201 47.7848C95.437 74.2931 74.1955 95.8818 47.8757 95.9995C21.5525 96.1172 0.120677 74.7236 0.00047986 48.2152C-0.116378 21.7035 21.1251 0.118179 47.445 0.000483373C73.7681 -0.117212 95.2 21.2731 95.3168 47.7848H95.3201Z" fill="#8DC63F"></path>
                            <path d="M91.7276 47.7979C91.8378 72.3122 72.1955 92.2733 47.859 92.3843C23.519 92.4953 3.69988 72.7124 3.58969 48.1981C3.47951 23.6839 23.1217 3.72273 47.4616 3.61177C71.7982 3.50416 91.6174 23.2837 91.7276 47.7979Z" fill="#F9E988"></path>
                            <path d="M70.0087 32.1144C66.8101 31.183 63.498 29.8581 60.1392 28.5231C59.9455 27.6756 59.201 26.6198 57.6918 25.3251C55.4982 23.4083 51.3781 23.4588 47.819 24.3062C43.8892 23.3747 40.0062 23.0418 36.2801 23.943C5.81008 32.4003 23.0851 53.0239 11.8967 73.7618C13.4893 77.1615 30.6475 97.0083 55.4749 91.6817C55.4749 91.6817 46.9843 71.1321 66.1457 61.2659C81.6879 53.266 92.9163 38.4095 70.0054 32.1111L70.0087 32.1144Z" fill="#8BC53F"></path>
                            <path d="M49.9291 37.0607C49.9291 41.8022 46.1128 45.6424 41.4085 45.6424C36.7041 45.6424 32.8878 41.8022 32.8878 37.0607C32.8878 32.3193 36.7041 28.4824 41.4085 28.4824C46.1128 28.4824 49.9291 32.3227 49.9291 37.0607Z" fill="white"></path>
                            <path d="M47.4049 37.1416C47.4049 40.474 44.7205 43.1776 41.4117 43.1776C38.103 43.1776 35.4186 40.4774 35.4186 37.1416C35.4186 33.8057 38.103 31.1055 41.4117 31.1055C44.7205 31.1055 47.4049 33.8091 47.4049 37.1416Z" fill="#58595B"></path>
                            <path d="M80.6726 49.4091C73.7713 54.3086 65.9151 58.0244 54.7802 58.0244C49.5683 58.0244 48.5099 52.4457 45.0643 55.1796C43.2847 56.5919 37.0144 59.7495 32.0362 59.5108C27.0146 59.2687 18.9982 56.3296 16.7445 45.6328C15.853 56.3296 15.3989 64.2119 11.4091 73.2441C19.3521 86.0527 38.2865 95.9324 55.4747 91.6853C53.6283 78.6951 64.9001 65.9739 71.2505 59.4637C73.6545 56.9988 78.262 52.9736 80.6726 49.4091Z" fill="#8BC53F"></path>
                            <path d="M80.4024 49.7321C78.2588 51.6993 75.708 53.1587 73.1104 54.4432C70.4861 55.6908 67.7516 56.7063 64.927 57.4428C62.1124 58.1759 59.1709 58.7273 56.1927 58.4583C53.2679 58.1994 50.1761 57.1637 48.2029 54.9207L48.2964 54.8131C50.7304 56.3936 53.5049 56.9485 56.2795 57.0292C59.054 57.1032 61.882 56.8947 64.6699 56.3264C67.4511 55.748 70.1823 54.8838 72.8233 53.7875C75.4576 52.6913 78.0619 51.4235 80.3089 49.6211L80.399 49.7287L80.4024 49.7321Z" fill="#58595B"></path>
                        </svg>
                    </a>
                </div>
                <button className={'modal-close'} onClick={() => setVisible(null)}>
                    <svg style={{height: '24px', fill: 'white'}} viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
            </div>

            <div style={{display: "flex", justifyContent: 'center'}}>
                <div style={{textAlign: 'center'}}>
                    <div className={'section-title title-secondary'}>
                        {t('price')}
                    </div>
                    <div>
                        {roundToThreeSignificantDigits(coinData.price)} {getCurrencyValueByKey(currency)}
                        {/*4,56 млрд $*/}
                    </div>
                </div>

                <div style={{marginLeft: '20px', textAlign: 'center'}}>
                    <div className={'section-title title-secondary'}>
                        {t('market_cap')}
                    </div>
                    <div>
                        {numeral(coinData.marketcap).format('0.00 a')}
                        {/*4,56 млрд $*/}
                    </div>
                </div>

                <div style={{marginLeft: '20px', textAlign: 'center'}}>
                    <div className={'section-title title-secondary'}>
                        {t('volume24')}
                    </div>
                    <div>
                        {numeral(coinData.volume).format('0.00 a')}
                        {/*201,36 млн $*/}
                    </div>
                </div>
            </div>

            {isChartLoading || error != null
                ? <div style={{height: '239px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {isChartLoading
                        ? <span>{t('loading')}...</span>
                        : null
                    }

                    {error != null
                        ? <span>{t('error')}: {error}</span>
                        : null
                    }

                </div>
                : <>
                    {chartData != null
                        ? <div style={{ height: '239px' }}>
                            <Line
                                height={239}
                                ref={chartRef}
                                data={chartData}
                                options={options}
                                plugins={[crosshairLabel]}
                            >
                            </Line>

                        </div>
                        : null
                    }</>
            }


            <div style={{
                color: "#ccc",
                padding: '0 10px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div>{t('date')}:</div>
                <div>{crossDate}</div>
            </div>
            {/*<div><span>Цена: {crossPrice}</span></div>*/}

            <div style={{marginTop: '20px', display: 'flex', justifyContent: 'space-between', padding: '0 5px 5px'}}>
                {appType === AppType.CMC
                    ? <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.HOUR ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.HOUR)}>
                        <div className={'title-secondary'}>{t('hour')}</div>
                        {coinData.performance.hour != null
                            ? <div className={`title-secondary ${coinData.performance.hour > 0 ? 'green' : 'red'}`}>{coinData.performance.hour}%</div>
                            : <div>-</div>
                        }
                    </button>
                    : null
                }
                <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.DAY ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.DAY)}>
                    <div className={'title-secondary'}>{t('day')}</div>
                    {coinData.performance.day != null
                        ? <div className={`title-secondary ${coinData.performance.day > 0 ? 'green' : 'red'}`}>{coinData.performance.day}%</div>
                        : <div>-</div>
                    }
                </button>
                <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.WEEK ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.WEEK)}>
                    <div className={'title-secondary'}>{t('week')}</div>
                    {coinData.performance.week != null
                        ? <div className={`title-secondary ${coinData.performance.week > 0 ? 'green' : 'red'}`}>{coinData.performance.week}%</div>
                        : <div>-</div>
                    }
                </button>
                <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.MONTH ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.MONTH)}>
                    <div className={'title-secondary'}>{t('month')}</div>
                    {coinData.performance.month != null
                        ? <div className={`title-secondary ${coinData.performance.month > 0 ? 'green' : 'red'}`}>{coinData.performance.month}%</div>
                        : <div>-</div>
                    }
                </button>
                {appType === AppType.CMC
                    ? <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.YEAR ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.YEAR)}>
                        <div className={'title-secondary'}>{t('year')}</div>
                        {coinData.performance.year != null
                            ? <div className={`title-secondary ${coinData.performance.year > 0 ? 'green' : 'red'}`}>{coinData.performance.year}%</div>
                            : <div>-</div>
                        }
                    </button>
                    : null
                }
            </div>

        </div>
    );
};
export default ChartPage;
